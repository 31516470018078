import React from 'react';
import { GlobalStyles } from 'twin.macro';

import Home from './home';

export default function Index() {
  return (
    <>
      <GlobalStyles />
      <Home />
    </>
  );
}
